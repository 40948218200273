import React from 'react';

const Login = (props) => {
    const [state, setState] = React.useState({
        username: "",
        password: "",
        message: "Please sign in!",
        isLoading: false,
    });

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setState({...state, [name]: value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setState({...state, isLoading: true, message: "Signing you in..."});

        fetch("/api/v1/user/authenticate", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(state)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if(data.status === 1){
                setState({...state, isLoading: false, message: "Login success!"});
                props.onChangeState(!props.onChangeState);
            }else{
                setState({...state, isLoading: false, message: data.error});
            }
        }).catch((err) => {
            setState({...state, isLoading: false, message: err.TypeError});
        });
    }

    return(
        <>
        <h2 style={{textAlign: "center"}}>SenoReg Online</h2>
        <hr />
        <p style={{textAlign: "center"}}>
            {state.message}&nbsp;<img src="/loading.gif" alt='loading'
            style={{visibility: (state.isLoading ? "visible" : "hidden")}} />
        </p>
        <form onSubmit={handleSubmit}>
            <div className="mb-3 mt-3">
                <label htmlFor="username">Username / Email:</label>
                <input type="text" className="form-control" placeholder="Username/email/phone"
                name="username" value={state.username} onChange={handleChange} />
            </div>
            <div className="mb-3">
                <label htmlFor="pwd">Password:</label>
                <input type="password" className="form-control" placeholder="Enter password"
                name="password" value={state.password} onChange={handleChange} />
            </div>
            <div className="mb-3">
            <button type="submit" className="btn btn-primary">Sign&nbsp;In</button>
            </div>
        </form>
        </>
    );
};
  
export default Login;
  