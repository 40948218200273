import React from "react";
// import LoadingOverlayWrapper from "react-loading-overlay-ts";
import './App.css';
import Login from "./components/Login";
import Reg from "./components/Reg";

function App() {
  const [state, setState] = React.useState({
    isLogin: false,
    userDetail: {},
  });
  const is_loading = React.useRef(false);
  
  React.useEffect(()=>{
    checkLogin();
  }, []);
  
  const checkLogin = () => {
    fetch('/api/v1/checkToken')
      .then(res => {
        if (res.status === 200 || res.status === 401) {
          return res.json();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .then((data) => {
        if(typeof data !== 'undefined'){
          if(data.status === 1){
            let ar = data.data.split(":");
            let userDetail = {id:ar[0], name:ar[1], utId:ar[2], utAlias:ar[3], utName: ar[4]};
            setState({...state, isLogin: true, userDetail: userDetail});
            is_loading.current = false;
          }else{
            setState({...state, isLogin: false, userDetail: {}});
            is_loading.current = false;
          }
        }
      })
      .catch(err => {
        setState({...state, isLogin: false, userDetail: {}});
        is_loading.current = false;
        console.error(err);
      });
  }

  if(is_loading.current){
    return(
      <div className="App" style={{paddingTop: "100px"}}>
        <img className="App-logo img-responsive" src="/logo512.png"
          style={{height: "50vh"}} />
      </div>
    );
  }
  if(state.isLogin){
    return(
      <Reg userDetail={state.userDetail} onChangeState={checkLogin} />
    );
  }else{
    return(
      <Login onChangeState={checkLogin} />
    );
  }
}

export default App;