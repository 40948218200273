import React from 'react';
import LoadingOverlayWrapper from "react-loading-overlay-ts";

const Reg = (props) => {
    const [state, setState] = React.useState({
        ar_branch: [],
        branch_id: "1",
        branch_name: "",
        ver: "2",
        ar_app: [],
        app_id: "",
        sn: "",
        an: "",
        message: "",
        isLoading: false,
    });

    const inputAN = React.useRef();
    const tmpData = React.useRef();

    const copyToClipboard = (e) => {
        inputAN.current.focus();
        inputAN.current.select();
        document.execCommand('copy');
        setState({...state, message: 'Activation Copied!' });
      };

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'ver'){
            loadApp(value);
        }else if(name === 'app_id' || name === 'sn'){
            setState({...state, [name]: value, an: "", message: ""});
        }else{
            setState({...state, [name]: value});
        }
    }

    const loadBranch = () => {
        setState({...state, isLoading: true});
        fetch("/api/v1/reg/branch", {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }).then((response) => {
            setState({...state, isLoading: false});
            return response.json();
        }).then((data) => {
            if(data.status === 1){
                tmpData.current = data.data;
                // setState({...state, an: "", ar_branch: data.data, message: "Branch list loaded!", isLoading: false});
                loadApp(state.ver, true);
            }else{
                setState({...state, message: data.error, isLoading: false});
            }
        }).catch((err) => {
            setState({...state, message: err.TypeError, isLoading: false});
        });
    }

    const loadApp = (ver, first=false) => {
        setState({...state, isLoading: true});
        fetch("/api/v1/reg/app", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ver: ver})
        }).then((response) => {
            setState({...state, isLoading: false});
            return response.json();
        }).then((data) => {
            if(data.status === 1){
                if(first){
                    setState({...state, ar_branch: tmpData.current, ver: ver, app_id: data.data[0].id, an: "", ar_app: data.data, 
                        message: "", isLoading: false});
                }else{
                    setState({...state, ver: ver, app_id: data.data[0].id, an: "", ar_app: data.data, 
                        message: "", isLoading: false});
                }
            }else{
                setState({...state, message: data.error, isLoading: false});
            }
        }).catch((err) => {
            setState({...state, message: err.TypeError, isLoading: false});
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // alert(JSON.stringify(state)); return;
        setState({...state, isLoading: true});
        fetch("/api/v1/reg/getan", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({branch_id: state.branch_id, ver: state.ver, 
                branch_name: state.branch_name, 
                id: state.app_id, sn: state.sn})
        }).then((response) => {
            setState({...state, isLoading: false});
            return response.json();
        }).then((data) => {
            if(data.status === 1){
                // alert(JSON.stringify(data));
                setState({...state, an: data.data.an, message: "Activation Generated!", isLoading: false});
            }else{
                setState({...state, message: data.error, isLoading: false});
            }
        }).catch((err) => {
            setState({...state, message: err.TypeError, isLoading: false});
        });
    }

    const signOut = (e) => {
        e.preventDefault();
        setState({...state, isLoading: true});
        fetch("/api/v1/user/logout", {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }).then((response) => {
            setState({...state, isLoading: false});
            return response.json();
        }).then((data) => {
            if(data.status === 1){
                setState({...state, message: "Successfully logged out!", isLoading: false});
                props.onChangeState(!props.onChangeState);
            }else{
                setState({...state, message: data.error, isLoading: false});
            }
        }).catch((err) => {
            setState({...state, message: err.TypeError, isLoading: false});
        });
    }

    React.useEffect(()=>{
        // console.log("Loading from effect");
        loadBranch();
        // loadApp(state.ver);
    }, []);

    return(
        <LoadingOverlayWrapper active={state.isLoading} spinner text='Loading data...' >
        <h2 style={{textAlign: "center"}}>SenoReg Online</h2>
        <hr />
        <p style={{textAlign: "center"}}>
            {props.userDetail.name}<br />[<a href='#' onClick={signOut}>Sign Out</a>]
        </p>
        <form onSubmit={handleSubmit}>
            <div className="mb-3 mt-3">
                <label htmlFor="branch_id">Branch:</label>
                <select className="form-select" name="branch_id" value={state.branch_id} onChange={handleChange}>
                    {state.ar_branch.map((branch) => <option key={branch.id} value={branch.id}>{branch.name}</option>)}
                    <option value="">New Branch:</option>
                </select>
                <input type="text" className="form-control" placeholder="Enter branch name" name="branch_name"
                    value={state.branch_name} onChange={handleChange} style={{display: state.branch_id==="" ? "inline-block" : "none"}} />
            </div>
            <div className="mb-3 mt-3">
                <label htmlFor="ver">Version:</label>
                <select className="form-select" name="ver" value={state.ver} onChange={handleChange}>
                    <option value="1">Ver 1 (Old SMR, SMC, AMK)</option>
                    <option value="2">Ver 2 (FlexiLIS, FlexiLink)</option>
                </select>
            </div>
            <div className="mb-3 mt-3">
                <label htmlFor="app_id">Application:</label>
                <select className="form-select" name="app_id" value={state.app_id} onChange={handleChange}>
                    {state.ar_app.map((app) => <option key={app.id} value={app.id}>{app.name}</option>)}
                </select>
            </div>
            <div className="mb-3 mt-3">
                <label htmlFor="sn">Serial Number:</label>
                <input type="text" className="form-control" placeholder="Enter serial number" name="sn"
                    value={state.sn} onChange={handleChange} />
            </div>
            <div className="mb-3 mt-3">
                <label htmlFor="an">Activation Number:</label>
                <input type="text" className="form-control" placeholder="Activation number" name="an"
                    value={state.an} ref={inputAN} onChange={handleChange} />
            </div>
            <button type="submit" className="btn btn-primary">Generate</button>&nbsp;
            <input type="button" className="btn btn-primary" value={"Copy"} onClick={copyToClipboard} />&nbsp;&nbsp;
            <span>{state.message}&nbsp;<img src="/loading.gif" alt='loading'
            style={{visibility: (state.isLoading ? "visible" : "hidden")}} /></span>
        </form>
        </LoadingOverlayWrapper>
    );
};
  
export default Reg;
  